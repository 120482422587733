<template>
  <div class="m-auto w-1/3 mt-32">
    <HttpGet :url="url('/api/1.0/language/' + $route.params.id + '/get')" v-model="language">
      <LanguageForm :model="language.data" :endpoint="'/api/1.0/language/' + $route.params.id + '/edit'" title="Edit Language" label="Edit Language"/>
    </HttpGet>
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator'
import LanguageForm from '@/views/language/form/LanguageForm'
import { HttpGet } from '../../lib/mavejs'
import { url } from '@/functions'

@Component({
  components: { LanguageForm, HttpGet },
  methods: { url }
})
export default class EditLanguage extends Vue {
  language = {}
}
</script>
